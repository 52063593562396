import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
// import { FaBars } from "react-icons/fa6";
import { FaBarsStaggered } from "react-icons/fa6";
import { useCart } from "../Contexts/CartContext";
import Slider from "react-slick";
import SearchBar from "../Shared/SearchBar";
import styled from "styled-components";
import { FaShoppingCart } from "react-icons/fa";
import { Accordion } from "react-bootstrap";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { cartCount } = useCart();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const settings = {
    fade: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <></>,
    prevArrow: <></>,
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search/${searchTerm}`);
      setSearchTerm("");
    }
  };

  const hideMobileCanvas = () => {
    setShowMenu(false);
  };

  return (
    <>
      <div className="backdrop"></div>
      <section className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="header-top-welcome text-center">
                <Slider {...settings}>
                  <p>Welcome to Vyra Herbals: Truth in Every Drop!</p>
                  <p>FREE Shipping Available on All Products</p>
                  <p>ISO 9001 : 2015 & GMP Certified</p>
                  {/* <p>Use coupon code: VYRABIRTHDAY - to get flat Rs.100/- off.</p> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StyledHeader className="header-part">
        <div className="container">
          <div className="header-content">
            <div className="header-media-group">
              <button className="toggle" onClick={() => setShowMenu(true)}>
                <FaBarsStaggered />
              </button>
            </div>
            <div className="header-logo-and-list">
              <Link to="/" className={"header-logo"}>
                <img src="/assets/images/logo-final.png" alt="logo" />
              </Link>

              <ul className="navbar-list">
                <li className="navbar-item">
                  <Link className="navbar-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="navbar-item dropdown">
                  <span
                    className="navbar-link dropdown-arrow"
                    data-bs-toggle="dropdown"
                  >
                    Shop
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/search/all")}
                      >
                        All Products
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/category/hair-oil")}
                      >
                        Hair Oil
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/category/shampoo")}
                      >
                        Shampoo
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/category/combs")}
                      >
                        Neem Combs
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/category/scalp-massager")}
                      >
                        Scalp Massager
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/category/kits")}
                      >
                        Combos & Kits
                      </button>
                    </li>
                  </ul>
                </li>
                <li className="navbar-item dropdown">
                  <span
                    className="navbar-link dropdown-arrow"
                    data-bs-toggle="dropdown"
                  >
                    Reviews
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/#customerStoriesSection")}
                      >
                        Customer Stories
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/#customerTestimonials")}
                      >
                        Customer Testimonials
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => navigate("/testimonials#images")}
                      >
                        Before & After Images
                      </button>
                    </li>
                  </ul>
                </li>
                {/* <li className="navbar-item">
                  <Link className="navbar-link" to="/category">
                    Blog
                  </Link>
                </li> */}
                <li className="navbar-item">
                  <Link className="navbar-link" to="/about">
                    About Us
                  </Link>
                </li>
                <li className="navbar-item dropdown">
                  <span
                    className="navbar-link dropdown-arrow"
                    data-bs-toggle="dropdown"
                  >
                    Help
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/faqs">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="navbar-item">
                  <Link className="navbar-link" to="/track-order">
                    Track Order
                  </Link>
                </li>
              </ul>
            </div>
            <div className="header-widget-group">
              <section className="search-section">
                <SearchBar
                  id="search-input"
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </section>

              <button className="header-widget header-cart" title="Cartlist">
                <Link to="/cart">
                  <FaShoppingCart />
                </Link>
                <sup>{cartCount}</sup>
              </button>
            </div>
          </div>
        </div>
      </StyledHeader>

      <StyledOffCanvas
        show={showMenu}
        onHide={hideMobileCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-menu">
            <ul className="header-top-list">
              <li>
                <Link to="/" onClick={hideMobileCanvas}>
                  Home
                </Link>
              </li>
              <StyledAccordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Shop</Accordion.Header>
                  <Accordion.Body>
                    <Link to="search/all" onClick={hideMobileCanvas}>
                      All Products
                    </Link>

                    <Link to="/category/hair-oil" onClick={hideMobileCanvas}>
                      Hair Oil
                    </Link>
                    <Link to="/category/shampoo" onClick={hideMobileCanvas}>
                      Shampoo
                    </Link>
                    <Link to="/category/combs" onClick={hideMobileCanvas}>
                      Neem Combs
                    </Link>
                    <Link
                      to="/category/scalp-massager"
                      onClick={hideMobileCanvas}
                    >
                      Scalp Massager
                    </Link>
                    <Link to="/category/kits" onClick={hideMobileCanvas}>
                      Combos & Kits
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Reviews</Accordion.Header>
                  <Accordion.Body>
                    <Link
                      to="/#customerStoriesSection"
                      onClick={hideMobileCanvas}
                    >
                      Customer Stories
                    </Link>
                    <Link
                      to="/#customerTestimonials"
                      onClick={hideMobileCanvas}
                    >
                      Customer Testimonials
                    </Link>
                    <Link to="/testimonials#images" onClick={hideMobileCanvas}>
                      Before & After Images
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <li>
                  <Link to="/" onClick={hideMobileCanvas}>
                    Blog
                  </Link>
                </li> */}
                <li>
                  <Link to="/about" onClick={hideMobileCanvas}>
                    About Us
                  </Link>
                </li>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Help</Accordion.Header>
                  <Accordion.Body>
                    <Link to="/contact" onClick={hideMobileCanvas}>
                      Contact Us
                    </Link>
                    <Link to="/faqs" onClick={hideMobileCanvas}>
                      FAQs
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <li>
                  <Link to="/track-order" onClick={hideMobileCanvas}>
                    Track Order
                  </Link>
                </li>
              </StyledAccordion>
            </ul>
            <ul className="social-container">
              <li>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.facebook.com/vyraherbals"
                  className="icofont-facebook"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.instagram.com/vyraherbals"
                  className="icofont-instagram"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.youtube.com/@vyraherbals"
                  className="fab fa-youtube"
                />
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </StyledOffCanvas>
    </>
  );
};

export default Header;

const StyledHeader = styled.section`
  &.header-part {
    padding: 0.5rem 0;
    background: var(--white);
    transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    box-shadow: 0 9px 10px -8px #0000001f !important;
    position: sticky;
    top: 0;
    z-index: 99;
    margin-top: 1px;

    .header-logo-and-list {
      display: flex;

      .header-logo {
        margin-right: 3rem;
        img {
          width: auto;
          height: 3rem;
        }
      }
      .navbar-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 2rem;
        .navbar-link {
          font-size: 1rem !important;
          cursor: pointer;
        }

        .dropdown {
          .dropdown-menu {
            padding: 0;
            li {
              margin: 0;
            }
          }
          .dropdown-item {
            &:active {
              background: var(--primary);
            }
          }
        }
      }
    }

    .header-widget-group {
      display: flex;
      align-items: center;
      justify-content: center;
      .header-widget {
        /* padding-top: 10px; */
        margin-left: 20px;
        &:first-child {
          margin-left: 0px;
        }
      }
      .header-cart {
        color: var(--white);
        background: var(--chalk);
        border-radius: 50%;
        width: 1.6rem;
        svg path {
          fill: var(--primary);
        }
      }
    }

    &.active {
      position: sticky;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 3;
      background: var(--white);
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
      transition: all linear 0.3s;
      -webkit-transition: all linear 0.3s;
      -moz-transition: all linear 0.3s;
      -ms-transition: all linear 0.3s;
      -o-transition: all linear 0.3s;
      .header-widget:hover i {
        background: var(--primary);
      }
      .header-widget sup {
        border-color: var(--white);
      }
      .header-form {
        background: var(--white);
        border-color: var(--primary);
      }
    }
  }

  //bootstrap break-point: md
  @media (max-width: 576px) {
    &.header-part {
      overflow-x: hidden !important;
      .header-content {
        margin-bottom: 2.4rem;
        .search-section {
          position: absolute;
          bottom: 0.6rem;
          left: 0.6rem;
          right: 0.6rem;
        }
      }
    }
  }

  @media (min-width: 991px) and (max-width: 1200px) {
    .header-content {
      .header-logo-and-list {
        .header-logo {
          margin-right: 1rem;
        }
        .navbar-list {
          padding-right: 1rem;
        }
      }
    }
  }
`;

const StyledOffCanvas = styled(Offcanvas)`
  .mobile-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .social-container {
      display: flex;
      justify-content: end;
      gap: 0.5rem;
      border-top: 1px solid var(--primary);
    }
    .header-top-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        margin-left: 20px;
        a {
          font-size: 16px;
          color: var(--white);
          letter-spacing: 0.3px;
          white-space: nowrap;
          text-transform: capitalize;
          transition: all linear 0.3s;
          -webkit-transition: all linear 0.3s;
          -moz-transition: all linear 0.3s;
          -ms-transition: all linear 0.3s;
          -o-transition: all linear 0.3s;
          &:hover {
            color: var(--green-chalk);
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    ul.header-top-list li {
      a {
        font-size: 16px;
        padding: 10px 15px;
        border-bottom: 1px solid rgb(130 85 62);
        width: 100%;
        color: #000 !important;
      }
      &:last-child {
        a:last-child {
          border-bottom: none;
        }
      }
    }
    .header-top-list li {
      margin-left: 0px !important;
    }
    .offcanvas-body {
      padding-top: 0px !important;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    .accordion-header {
      border-bottom: 1px solid var(--primary);
      padding: 0 15px;
      .accordion-button {
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        &:not(.collapsed),
        &:focus {
          color: var(--text);
          box-shadow: none;
          border: none;
        }
      }
    }
    .accordion-body {
      border-bottom: 1px solid var(--primary);
      padding: 0.4rem 1rem;
      display: flex;
      flex-direction: column;
      a {
        font-size: 14px;
        padding: 0.1rem 1.5rem;
        color: var(--text);
        &:last-child {
          border: none !important;
        }
      }
    }
  }
`;
