import { createContext, useEffect, useState } from "react";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import Home from "./Pages/Home";
import LazyLoad from "./routes/LazyLoad";
import { useLocation } from "react-router-dom";

// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
// import './App.css'

function App() {
  const { path } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return (
    <>
      <Header />
      {/* <Home /> */}

      <LazyLoad />

      <Footer />
    </>
  );
}

export default App;
