import React from "react";

class Utility extends React.Component {
  /**
   * Scrolls the page smoothly to a specific section identified by its hash (ID).
   * If an offset is provided, it adjusts the scroll position accordingly.
   *
   * @param {string} hash - The CSS selector or ID of the section to scroll to (e.g., '#section1').
   * @param {number} [offSet] - Optional. A number representing how much to offset the scroll position.
   *                            Useful when you have a fixed header or want to stop a little earlier.
   *
   * @example
   * // Scrolls to the section with the ID 'section1' smoothly
   * scrollToSection('#section1');
   *
   * // Scrolls to the section with the ID 'section2', but with a 100px offset (to cover sticky header height)
   * scrollToSection('#section2', 100);
   */
  static scrollToSection = (hash, offSet) => {
    const section = document.querySelector(hash);
    if (section) {
      //   if (offSet) {
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - (offSet || 0);

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      //   } else {
      //     section.scrollIntoView({ block: "start", behavior: "smooth" });
      //   }
    }
  };

  /**
   * Formats a given date string (YYYY-MM-DD) into a readable format: DD month YYYY.
   *
   * @param {string} dateString - The date string in the format YYYY-MM-DD.
   * @returns {string} - The formatted date in the format DD month YYYY.
   *
   * @example
   * // returns '15 September 2024'
   * formatDate('2024-09-15');
   */
  static formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = { day: "2-digit", year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  /**
   * Calculates average rating, rating statistics, review count based passed review list
   *
   * @param {array} reviews - List of reviews (each review object should contain rating)
   * @returns {object} - {average, stats, count}
   *
   * @example
   * // returns {avarage: 4.67, count:20, stats: {5:12, 4:8, 3:0, 2:0, 1:0}}
   * calculateRating(reviewList);
   */
  static calculateRating = (reviews) => {
    if (reviews?.length) {
      let reviewCount = reviews.length;
      let total = 0;
      let stats = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };

      reviews.forEach((curr) => {
        if (stats[Number(curr.rating)] !== undefined) {
          stats[Number(curr.rating)] += 1;
          total += Number(curr.rating);
        }
      });

      return {
        stats,
        average: (total / reviewCount || 0).toFixed(2),
        count: reviewCount,
      };
    }
  };
}

export default Utility;
